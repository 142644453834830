<template>
  <div style="height:100%;display:flex;height:100%">
     <el-container >
         
      <Aside class="as" @getTreeData='testData' />
      <el-container>
  
      <div class="rights" id="rights" style="width:100%">   
         <router-view></router-view>
      </div>
        </el-container>
     </el-container>
  </div>
</template>

<script>
import Aside  from '@/components/aside4.vue'

export default {
    components:{
        Aside
    },
    methods:{
        testData(data){
            if(data == false){
           
                document.getElementById('rights').classList.add("tst");
            }else{
                  document.getElementById('rights').classList.remove("tst");
            }
    },
    }
}
</script>

<style  >
 ul {
     list-style: none;padding:0
 }
 .as{
    z-index: 300;
    position: fixed;
    top: 0px;
    left: 0;
    bottom: 0;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;

    background-color: #2d2e36;
 }
 .rights{
     margin-left:240px;
 }
 .tst{
       margin-left:70px !important;
 }
</style>